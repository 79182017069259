import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import Cookies from "js-cookie";

class AuthService extends ApiService {
  async loginStaff(data: any): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/staff/login",
        data,
        {
          headers: { "Content-Type": this.getContentType(data) },
        }
      );

      const result = response.data;
      if (response.status === 200) {
        Cookies.set("authToken", result.token);
      }
      return result;
    } catch (error) {
      console.error("Error logging in staff:", error);
      throw error;
    }
  }

  async logoutStaff(): Promise<void> {
    try {
      // Assuming you have an endpoint '/staff/logout' for logout
      await this.axiosInstance.post("/staff/logout");
      await this.invalidateCacheByKey("get/staff/getAll");
    } catch (error) {
      console.error("Error logging out staff:", error);
      throw error;
    }
  }
}

const authService = new AuthService();
export default authService;
